import React from "react";
import styles from "./ClientDashboard.module.scss";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import GenDropdownCheck from "../../components/GenDropdownCheck";
import TicketDetail from "../../components/TicketDetail";
import { DataService } from "../../store/DataService";
import {DateOnly, formatPhoneNumber, PhoneTest, CleanDate, ACTIONADDNOTE, PRIORITIES, Date12, calculateAgeInYears,PRIORITY_HOLDING} from "../../AppConstants";

const ClientDashboard = (props) => {
    const [matrix,setMatrix] = React.useState({});
    const [refreshneeded,setRefreshNeeded] = React.useState(false);
    const [tickets,setTickets] = React.useState([]);
    const [ticket,setTicket] = React.useState(null);
    const [showtickets,setShowTickets] = React.useState(false);
    const [showticket,setShowTicket] = React.useState(false);
    const [selectedagents,setSelectedAgents] = React.useState([])
    const [showagents,setShowAgents] = React.useState(false);
    const [agenttickets,setAgentTickets] = React.useState("");
    const [showagenttickets,setShowAgentTickets] = React.useState(false);
    const [ticketsortfield,setTicketSortField] = React.useState('');
    const [ticketsortorder,setTicketSortOrder] = React.useState('');
    const [holding, setHolding] = React.useState(0);
    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [agentsortfield,setAgentSortField] = React.useState('');
    const [agentsortorder,setAgentSortOrder] = React.useState('');
    const [pracsortfield,setPracSortField] = React.useState('');
    const [pracsortorder,setPracSortOrder] = React.useState('');
    const mtypelist = [7,16,17,20,18,99];

    const initDashboard = () => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        console.log('CDB Init called')
        dataservice.initclientdashboard().then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.MATRIX);
            setTickets([]);
            setShowAgentTickets(false);
            setShowAgents(false);
            setShowTickets(false);
            setShowTicket(false);
            console.log('DB Init matrix ready')
        })
    }

    React.useEffect(()=> {
        initDashboard();
    },[refreshneeded])

    const handleRefresh = (e) => {
        initDashboard();
    }

    const handleEntryClick = (entry) => {
        //setSelectedEntry(entry);
        const ticketid = entry.ticketid || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setTicket(data.TICKET);
            setShowTicket(true);
        })
    };

    const ShowSelectedTickets = () => {
        if (tickets.length < 1) {
            return null;
        }

        if (ticketsortfield.length > 0) {
            tickets.sort((a,b)=> {
                const fldA = a[ticketsortfield];
                const fldB = b[ticketsortfield];
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return ticketsortorder === 'desc' ? -compare : compare;
            })
        }

        const handleSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = ticketsortfield;
            let torder = ticketsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setTicketSortField(fld);
            setTicketSortOrder(torder);
        }
        const formatMinutesToHoursMinutes = (totalMinutes) => {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours}:${minutes}`;
          }



        return (
            <React.Fragment>
                <div style={{marginTop:"18px",fontSize:"22px",fontFamily:"Inter",width:"100%",borderBottom:"1px solid gray"}}>Selected Tickets</div>
                <div className={styles.listrowhdr} style={{marginTop:"10px"}}>
                    <div id="ticketid" onClick={handleSortClick}>Ticket #{ticketsortfield==='ticketid' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='ticketid' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="msgname" onClick={handleSortClick}>Message Type{ticketsortfield==='msgname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='msgname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="priority" onClick={handleSortClick}>Priority{ticketsortfield==='priority' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='priority' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="createdate" onClick={handleSortClick}>Created Date{ticketsortfield==='createdate' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='createdate' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="eventname" onClick={handleSortClick}>State{ticketsortfield==='eventname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='eventname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="age" onClick={handleSortClick}>Time in State{ticketsortfield==='age' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='age' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="firstname" onClick={handleSortClick}>First Name{ticketsortfield==='firstname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='firstname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="lastname" onClick={handleSortClick}>Last Name{ticketsortfield==='lastname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='lastname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="dob" onClick={handleSortClick}>DOB{ticketsortfield==='dob' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='dob' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="callbackphone" onClick={handleSortClick}>Phone{ticketsortfield==='callbackphone' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='callbackphone' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="pracname" onClick={handleSortClick}>Practice{ticketsortfield==='pracname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='pracname' && ticketsortorder === 'asc' ? '↑' : ''}</div>
                </div>
                <div style={{height:"10px"}}></div>
                {tickets.map((entry,i) => {
                    const ptage = calculateAgeInYears(entry.dob);
                    let phones = "";
                    let bg = null;
                    if (entry.priority===PRIORITY_HOLDING && holding===0) {
                        bg = "yellow";
                    }
                    let rowbg = "white";
                    if (entry===selectedEntry) {
                        rowbg = "lightgreen";
                    }
                    if (entry.callbackphone.length > 0) {
                        phones = formatPhoneNumber(entry.callbackphone);
                    }
                    if (entry.secondaryphone.length > 0) {
                        if (phones.length > 0) phones += " , "
                        phones += formatPhoneNumber(entry.secondaryphone);
                    }
                    return (
                        <div key={"phone_"+i} className={styles.listrow} onClick={()=>handleEntryClick(entry)}>
                            <div>{entry.ticketid}</div>
                            <div>{entry.msgname}</div>
                            <div style={{backgroundColor:bg}}>{PRIORITIES[entry.priority]}</div>
                            <div>{Date12(entry.createdate)}</div>
                            <div>{entry.eventname}</div>
                            <div>{formatMinutesToHoursMinutes(entry.age)}</div>
                            <div className={styles.gridcell}>{entry.firstname}</div>
                            <div className={styles.gridcell}>{entry.lastname}</div>
                            <div>{DateOnly(entry.dob)} ({ptage})</div>
                            <div>{phones}</div>
                            <div className={styles.gridcell}>{entry.pracname}</div>
                        </div>
                    )
                    
                })}
            </React.Fragment>
        )
    }

    function decimalToHoursMinutes(num) {
        const total = Math.floor(num);
        const hours = Math.floor(total / 3600); // Calculate hours
        const minutes = Math.floor((total % 3600) / 60); // Calculate minutes
        const seconds = total % 60; // Calculate remaining seconds
      
        // Pad single-digit values with leading zeros
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`; // Return formatted string
      }

      const findAgent = (uaid) => {
        const agents = matrix.AGENTS || [];
        let agent = null;
        agents.every((ag)=> {
            if (''+ag.uaid===''+uaid) {
                agent = ag;
                return false;
            }
            return true;
        })
        return agent;
    }

    const readTickets = (p) => {}

      const handleSelect = (e) => {
        const tid = e.target.id;
        const sa = tid.split("_");
        setShowTickets(false);
        if (sa.length > 1) {
            const uaid = sa[1];
            const cmd = sa[0];
            const ag = findAgent(uaid);
            let tidparm = '';
            let tidlist = [];
            if (ag) {
                console.log("Found "+uaid+" = " + ag.name);
                if (cmd==='wl') {
                    tidparm = ag.working;
                }
                if (cmd==='av') {
                    tidparm = ag.available;
                }
                if (cmd==='cl') {
                    tidparm = ag.closed;
                }
                if (tidparm.length) {
                    readTickets(tidparm.join(','));
                }
            } else {
                console.log("Unable to find Agent for "+uaid);
            }

        }
    }

    const ShowAgents = () => {

        if (agentsortfield.length > 0) {
            selectedagents.sort((a,b)=> {
                let fldA = a[agentsortfield];
                let fldB = b[agentsortfield];
                if (agentsortfield==='working' || agentsortfield==='closed' || agentsortfield==='available') {
                    fldA = fldA.length;
                    fldB = fldB.length;
                }
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return agentsortorder === 'desc' ? -compare : compare;
            })
        }

        const handleAgentSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = agentsortfield;
            let torder = agentsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setAgentSortField(fld);
            setAgentSortOrder(torder);
        }

        if (selectedagents.length < 1) return null;

        return (
            <div className={styles.matrixblock}><div style={{marginTop:"18px",fontSize:"22px",width:"100%",borderBottom:"1px solid gray"}}>Selected Agents</div>
                <div className={styles.matrixhdr} style={{cursor:"pointer",userSelect:"none"}}>
                    <div id="name" onClick={handleAgentSortClick}>Agent {agentsortfield==='name' && agentsortorder === 'desc' ? '↓' : agentsortfield==='name' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="created" onClick={handleAgentSortClick}>Login Time {agentsortfield==='created' && agentsortorder === 'desc' ? '↓' : agentsortfield==='created' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div id="duration" onClick={handleAgentSortClick}>Login Duration {agentsortfield==='duration' && agentsortorder === 'desc' ? '↓' : agentsortfield==='duration' && agentsortorder === 'asc' ? '↑' : ''}</div>
                    <div style={{cursor:"default"}}>Office Messages</div>
                    <div style={{cursor:"default"}}>Appointment Requests</div>
                    <div style={{cursor:"default"}}>Cancel/Confirm</div>
                    <div style={{cursor:"default"}}>Reschedules</div>
                    <div style={{cursor:"default"}}>Inquiry</div>
                    <div style={{cursor:"default"}}>Holding</div>
                </div>
                {selectedagents.map((ag,i)=> {
                    let agmsgtypes = ag.msgtypes || [];
                    return (
                        <div key={"agrow_"+i} className={styles.matrixrow}>
                            <div>{ag.name}</div>
                            <div className={styles.matrixblockcell} style={{textAlign:"left"}}>{Date12(ag.created)}</div>
                            <div className={styles.matrixblockcell}>{decimalToHoursMinutes(ag.duration)}</div>
                            {mtypelist.map((mt,i) => {
                                let hasit = 'N';
                                if (agmsgtypes.includes(mt)) hasit = 'Y';
                                return <div style={{paddingLeft:"20%"}} key={"mt_"+i} id={"amt_"+mt}>{hasit}</div>
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    const FindTickets = (pid,mtype) => {
        let pracmatrix = matrix.pracmatrix || {}
        let prac = pracmatrix[pid] || {}
        let tickets = [];
        if (mtype > 0) {
            let ticketmtypes = prac.tkmtypes || {};
            tickets = ticketmtypes[mtype] || [];    
        } else {
            tickets = prac.tickets || [];
        }
        return tickets; 
    }

    const FindAgents = (pid,mtype) => {
        let pracmatrix = matrix.pracmatrix || {}
        let prac = pracmatrix[pid] || {}
        let agents = [];
        if (mtype > 0) {
            let agentmtypes = prac.agentmtypes || {};
            agents = agentmtypes[mtype] || [];
        } else {
            agents = prac.ulist || [];
        }
        return agents;
    }

    const ShowAgentTickets = (ids) => {
        let ticketmatrix = matrix.ticketmatrix || {}
        let agentmatrix = matrix.agentmatrix || {}
        let sa = ids.split('_');
        let pid = sa[1];
        let mtype = sa[2];
        let tknums = FindTickets(pid,mtype);
        let foundagents = FindAgents(pid,mtype);
        console.log("tickets = "+tknums);
        console.log("agents = "+foundagents);
        let tickets = [];
        tknums.forEach((tkid)=> {
            tickets.push(ticketmatrix[tkid] || {});
        })
        setTickets(tickets);
        let agents = [];
        foundagents.forEach((uaid)=> {
            agents.push(agentmatrix[uaid] || {});
        })
        setSelectedAgents(agents);
        setShowTickets(true);
        setShowAgents(true);
    }

    const ShowMatrix = () => {
        let pracs = matrix.practices || []
        let pracmatrix = matrix.pracmatrix || {}
        let agents = matrix.agents || []
        let agentmatrix = matrix.agentmatrix || {}
        let tickets = matrix.tickets || []
        let ticketmatrix = matrix.ticketmatrix || {}

        if (pracsortfield.length > 0) {
            pracs.sort((a,b)=> {
                let fldA = a[pracsortfield];
                let fldB = b[pracsortfield];
                if (pracsortfield==='ulist' || pracsortfield==='tickets') {
                    fldA = fldA.length;
                    fldB = fldB.length;
                }
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return pracsortorder === 'desc' ? -compare : compare;
            })
        }

        
        const setSelectedTickets = (e) => {
            let id = e.target.id;
            let sa = id.split('_');
            if (sa[0] === 'tk') {
                let tickets = FindTickets(sa[1],0);
                if (tickets.length > 0) {
                    setTickets(tickets);
                    setShowAgents(false);
                    setShowTickets(true);
                }
            }
        }

        const selectAgents = (e) => {
            let id = e.target.id;
            let sa = id.split('_');
            if (sa[0] === 'ag') {
                let foundagents = FindAgents(sa[1],0);
                if (foundagents.length > 0) {
                    let agentmatrix = matrix.agentmatrix || {}
                    let agents = [];
                    foundagents.forEach((uaid)=> {
                        agents.push(agentmatrix[uaid] || {});
                    })
                    setSelectedAgents(agents);
                    setShowTickets(false);
                    setShowAgents(true);
                }
            }
        }
        
        const setSelectedAgentTickets = (e) => {
            let id = e.target.id;
            let sa = id.split('_');
            if (sa[0]==='at') {
                ShowAgentTickets(id);
            }
        }

        const handlePracSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = pracsortfield;
            let torder = pracsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setPracSortField(fld);
            setPracSortOrder(torder);
        }

        return (
            <React.Fragment>
                <div className={styles.matrixblock}>
                    <div className={styles.matrixhdr} style={{cursor:"default",userSelect:"none"}}>
                        <div id="pracname" onClick={handlePracSortClick} style={{cursor:"pointer",userSelect:"none"}}>Practice {pracsortfield==='pracname' && pracsortorder === 'desc' ? '↓' : pracsortfield==='pracname' && pracsortorder === 'asc' ? '↑' : ''}</div>
                        <div id="tickets" onClick={handlePracSortClick} style={{cursor:"pointer",userSelect:"none"}}>Active Tickets {pracsortfield==='tickets' && pracsortorder === 'desc' ? '↓' : pracsortfield==='tickets' && pracsortorder === 'asc' ? '↑' : ''}</div>
                        <div id="ulist" onClick={handlePracSortClick} style={{cursor:"pointer",userSelect:"none"}}>Agents {pracsortfield==='ulist' && pracsortorder === 'desc' ? '↓' : pracsortfield==='ulist' && pracsortorder === 'asc' ? '↑' : ''}</div>
                        <div id="officemsg">Office Messages</div>
                        <div id="apptreq">Appointment Request</div>
                        <div id="cancelconfirm">Cancel/Confirm</div>
                        <div id="resched">Reschedules</div>
                        <div id="inquiry">Inquiry</div>
                        <div id="holding">Holding</div>
                    </div>
                    {pracs.map((prac,i)=> {
                        let pid = prac.pracid || 0;
                        let practickets = prac.tickets || [];
                        let pracagents = prac.ulist || [];
                        let agentmtypes = prac.agentmtypes || {};
                        let ticketmtypes = prac.tkmtypes || {};
                        return <div key={i} className={styles.matrixrow}>
                                <div>{prac.pracname}</div>
                                <div id={"tk_"+pid} style={{cursor:"pointer",userSelect:"none"}} onClick={setSelectedTickets}>{practickets.length}</div>
                                <div id={"ag_"+pid} style={{cursor:"pointer",userSelect:"none"}} onClick={selectAgents}>{pracagents.length}</div>
                                {mtypelist.map((mtype) => {
                                    let agm = agentmtypes[mtype] || [];
                                    let numagents = agm.length;
                                    let tkm = ticketmtypes[mtype] || [];
                                    let numtks = tkm.length;
                                    let color = "black";
                                    if (numtks > 0 && numagents < 1) color = "red";
                                    return <div style={{color:color,cursor:"pointer",userSelect:"none"}} id={'at_'+pid+'_'+mtype} onClick={setSelectedAgentTickets}>{numagents} ({numtks})</div>
                                })}
                            </div>
                    })}
                </div>
            </React.Fragment>
        )
    }

    const ticketOnClose = (ticketid,stateid,priority) => {
        setShowTicket(false);
    }

    return (
        <React.Fragment>
            <div>
                <div className={styles.title}>Client Dashboard
                <span style={{marginLeft:"50px"}}><button style={{height:"26px",fontSize:"16px"}} onClick={handleRefresh}>Refresh</button></span>
                <span style={{marginLeft:"250px",fontSize:"16px"}}>Agents Available (Tickets)</span>
                </div>
                {ShowMatrix()}
                {showagents && ShowAgents()}
                {showagenttickets && ShowAgentTickets()}
                {showtickets && ShowSelectedTickets()}
                {showticket && <TicketDetail globals={props.globals} methods={props.methods} allowpriorityedit={false} disableworkflow={true} ticket={ticket} doClose={ticketOnClose} />}                
            </div>
        </React.Fragment>
    )
}

export default ClientDashboard;