import React from "react";
import styles from "./ClosedTickets.module.scss";
import GenButton from "../../components/GenButton";
import GenConfirm from "../../components/GenConfirm";
import AddNote from "../../components/AddNote";
import TicketDetail from "../../components/TicketDetail";
import { DataService } from "../../store/DataService";
import {DateOnly, formatPhoneNumber, PhoneTest, CleanDate, ACTIONADDNOTE, PRIORITY_HOLDING} from "../../AppConstants";

const ClosedTickets = (props) => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [birthday, setBirthday] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [ticketNumber, setTicketNumber] = React.useState(0);
    const [uaid,setUAID] = React.useState(0);
    const [pracid,setPracID] = React.useState(0);
    const [fromdt,setFromDt] = React.useState("");
    const [todt,setToDt] = React.useState("");
    const [entries, setEntries] = React.useState([]);
    const [workstates,setWorkStates] = React.useState({});
    const [selectedEntry, setSelectedEntry] = React.useState(null);
    const [searchdone,setSearchDone] = React.useState(false);
    const [shownote,setShowNote] = React.useState(false);
    const [showhold,setShowHold] = React.useState(false);
    const [showclose,setShowClose] = React.useState(false);
    const [ischild,setIsChild] = React.useState(props.ischild || false);
    const [matrix,setMatrix] = React.useState({users:[],practices:[]});
    const [ticketsortfield,setTicketSortField] = React.useState('');
    const [ticketsortorder,setTicketSortOrder] = React.useState('');
    const [fnfilter,setFNFilter] = React.useState('');
    const [lnfilter,setLNFilter] = React.useState('');
    const [insearch,setInSearch] = React.useState(false);

    React.useEffect(()=> {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.initclosedtickets().then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setMatrix(data.MATRIX || {});
        })
    },[props.globals.sessionguid])

    const dummy = () => {}

    const onCancel = (e) => {
        const cb = props.oncancel || dummy;
        cb();
    }


    const handleSubmit = (id) => {
        // Call server component to retrieve list of entries based on form input
        // Simulated server call
        //console.log('Form submitted with:', { firstName, lastName, birthday, phoneNumber, uaid, pracid });
        let haveentry = firstName.length > 0 | lastName.length > 0 | birthday.length > 0 | phoneNumber.length > 0 | ticketNumber > 0 | uaid > 0 | pracid > 0 | fromdt.length > 0 | todt.length > 0;
        if (!haveentry) {
            alert("At least 1 item needs to be filled in. Cancelling search.");
            return;
        }
        setSelectedEntry(null);
        
        let sdob = "";
        if (birthday.length > 0) {
            sdob = CleanDate(birthday);
            console.log("DOB = "+sdob);
            if (!sdob.length) {
                alert("Invalid DOB.");
                return;
            }
        }
        let sphone = "";
        if (phoneNumber.length > 0) {
            sphone = PhoneTest(phoneNumber);
            if (!sphone.length) {
                alert("Invalid Phone Number.");
                return;
            }
        }

        let obj = {fname:firstName,lname:lastName,dob:sdob,phone:sphone,uaid:uaid,pracid:pracid,fromdt:fromdt,todt:todt,tknum:ticketNumber};
        setInSearch(true);
        
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.searchclosedtickets(obj).then((data)=> {
            setInSearch(false);
            if (data.ERROR) {
                if (data.ERROR.length) {
                    alert(data.ERROR);
                    return;
                }
            }
            setEntries(data.TICKETS);
            let states = data.STATES || [];
            let indexstates = {};
            states.forEach((st)=> {
                indexstates[st.WorkFlowStateID] = st.Name;
            })
            setWorkStates(indexstates);
            setSearchDone(true);
        })
      };
    
    const handleEntryClick = (entry) => {
        //setSelectedEntry(entry);
        const ticketid = entry.ticketid || 0;
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.readticketdetail(props.globals.useraccountid,ticketid).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            setSelectedEntry(data.TICKET);
            /*
            const wf = data.TICKET.workflow || [];
            wf.forEach((ws)=> {
                if (ws.buttontext.toLowerCase()==='in-progress') {
                    setIPState(ws.workflowstateid);
                }
            })
            setShowTicket(true);
            */
        })
    };


    const cancelNote = () => {
        setShowNote(false);
    }

    const saveNote = (reason,cmt) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        //cmt = cmt.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');


        let obj = {
            ticketid:selectedEntry.ticketid,
            msgid:selectedEntry.msgid,
            msgtype:selectedEntry.msgtype,
            msgdt:"",
            respid:props.globals.useraccountid,
            resptype:'R',
            uaid:props.globals.useraccountid,
            actionid:ACTIONADDNOTE,
            note:cmt
        }
        dataservice.addticketnote(obj).then((data) => {
                if (data.ERROR) {
                    alert(data.ERROR);
                } else {
                    selectedEntry.notes = data.NOTES;
                    setShowNote(false);
                }
            })
        
    }

    const setAddNote = (e) => {
        setShowNote(true);
    }

    const SetCloseOK = (id) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.setticketevent(selectedEntry.ticketid,selectedEntry.finalstateid,selectedEntry.priority,props.globals.useraccountid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                let newentries = JSON.parse(JSON.stringify(entries));
                let selid = selectedEntry.ticketid;
                newentries = newentries.filter(obj=>obj.ticketid !== selid);
                setSelectedEntry(null);
                setEntries(newentries)
            }
            setShowClose(false);
        })
    }

    const SetCloseCancel = (id) => {
        setShowClose(false);
    }

    const SetClose = (id) => {
        setShowClose(true);
    }

    const SetHoldOK = (id) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.settickethold(selectedEntry.ticketid,1,props.globals.useraccountid).then((data)=> {
            if (data.ERROR) {
                alert(data.ERROR);
            } else {
                selectedEntry.priority = PRIORITY_HOLDING;
            }
            setShowHold(false);
        })
    }

    const SetHoldCancel = (id) => {
        setShowHold(false);
    }

    const SetHold = (id) => {
        setShowHold(true);
    }

    const ShowResults = () => {

        if (insearch) {
            return (
                <div className={styles.title} style={{marginTop:"20px"}}>Search Results - Searching...</div>
            )
        }

        if (ticketsortfield.length > 0) {
            entries.sort((a,b)=> {
                const fldA = a[ticketsortfield];
                const fldB = b[ticketsortfield];
                let compare = 0;
                if (fldA > fldB) compare = 1;
                if (fldA < fldB) compare = -1;
                return ticketsortorder === 'desc' ? -compare : compare;
            })
        }
        const filterArrayByField = (array, field, searchStr) => {
            return array.filter(obj => 
              obj[field].toLowerCase().includes(searchStr.toLowerCase())
            );
          }

        let filteredtickets = entries;
        if (fnfilter.length > 0) {
            filteredtickets = filterArrayByField(filteredtickets,'firstname',fnfilter);
        }
        if (lnfilter.length > 0) {
            filteredtickets = filterArrayByField(filteredtickets,'lastname',lnfilter);
        }

        const handleSortClick = (e) => {
            const fld = e.target.id;
            const oldfld = ticketsortfield;
            let torder = ticketsortorder;
            if (fld === oldfld) {
                torder = torder==='desc' ? 'asc' : 'desc';
            }
            setTicketSortField(fld);
            setTicketSortOrder(torder);
        }
        let numtickets = filteredtickets.length === 1 ? 'ticket' : 'tickets';
        return (
            <React.Fragment>
                <div className={styles.title} style={{marginTop:"20px"}}>Search Results ({filteredtickets.length} {numtickets})
                
                </div>
                <table className={styles.tickettable}>
                    <tr>
                        <th id="ticketid" onClick={handleSortClick}>Ticket #{ticketsortfield==='ticketid' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='ticketid' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                        <th id="msgname" onClick={handleSortClick}>Message Type{ticketsortfield==='msgname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='msgname' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                        <th id="createdate" onClick={handleSortClick}>Created Date{ticketsortfield==='createdate' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='createdate' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                        <th id="firstname" onClick={handleSortClick}>First Name{ticketsortfield==='firstname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='firstname' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                        <th id="lastname" onClick={handleSortClick}>Last Name{ticketsortfield==='lastname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='lastname' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                        <th id="dob" onClick={handleSortClick}>DOB{ticketsortfield==='dob' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='dob' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                        <th id="callbackphone" onClick={handleSortClick}>Phone{ticketsortfield==='callbackphone' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='callbackphone' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                        <th id="pracname" onClick={handleSortClick}>Practice{ticketsortfield==='pracname' && ticketsortorder === 'desc' ? '↓' : ticketsortfield==='pracname' && ticketsortorder === 'asc' ? '↑' : ''}</th>
                    </tr>
                    {filteredtickets.map((entry,i)=> {
                        let phones = '';
                        let bg = "white";
                        let rowbg = "white";
                        if (entry===selectedEntry) {
                            rowbg = "lightgreen";
                        }
                        if (entry.callbackphone.length > 0) {
                            phones = formatPhoneNumber(entry.callbackphone);
                        }
                        if (entry.secondaryphone.length > 0) {
                            if (phones.length > 0) phones += " , "
                            phones += formatPhoneNumber(entry.secondaryphone);
                        }
                        return (
                            <tr key={"phone_"+i} className={styles.trow} style={{backgroundColor:rowbg}} onClick={()=>handleEntryClick(entry)}>
                                <td className={styles.tcellfirst}>{entry.ticketid}</td>
                                <td className={styles.tcell}>{entry.msgname}</td>
                                <td className={styles.tcell}>{entry.createdate}</td>
                                <td className={styles.tcell} style={{backgroundColor:bg}}>{entry.firstname}</td>
                                <td className={styles.tcell} style={{backgroundColor:bg}}>{entry.lastname}</td>
                                <td className={styles.tcell}>{DateOnly(entry.dob)}</td>
                                <td className={styles.tcell}>{phones}</td>
                                <td className={styles.tcell}>{entry.pracname}</td>
                            </tr>
                        )
                    })}
                </table>
            </React.Fragment>
        )
    }

    const ShowButtons = () => {

        if (selectedEntry === null) {
            return null;
        }

        let isescalated = selectedEntry.statename==='ESCALATE';
        
        const note = selectedEntry.notes || "";
        let notes = note.split('\n').filter(Boolean);
        return (
            <React.Fragment>
                <div style={{display:"flex",marginTop:"12px",marginLeft:"10px"}}>
                    <div style={{display:"flex"}}>
                        <div><GenButton buttontext="Add Note" width="110" onclick={setAddNote} fontsize="16px" /></div>
                        {showhold && <div style={{marginLeft:"16px"}}><GenButton buttontext="Place on Hold" width="110" onclick={SetHold} fontsize="16px" /></div>}
                        {isescalated && <div style={{display:"flex"}}>
                            <div style={{marginLeft:"16px"}}><GenButton buttontext="Put in Queue" width="110" onclick={onCancel} fontsize="16px" /></div>
                            <div style={{marginLeft:"16px"}}><GenButton buttontext="Take Ownership" width="110" onclick={onCancel} fontsize="16px" /></div>
                            </div>}
                        <div style={{marginLeft:"16px"}}><GenButton buttontext="Close Ticket" width="110" onclick={SetClose} fontsize="16px" /></div>
                        {ischild && <div style={{marginLeft:"16px"}}><GenButton buttontext="Cancel Call" width="110" onclick={onCancel} fontsize="16px" /></div>}
                    </div>

                    <div style={{width:"700px",marginLeft:"20px",fontSize:"14px", display: 'flex', flexDirection: 'column',alignItems:"flex-start",fontWeight:"bold"}}>Notes:
                        {notes.map((n,i)=> {
                            return (
                                <div key={i} style={{fontWeight:"normal"}}>{n}</div>
                            )
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    const ticketOnClose = (ticketid,stateid,priority) => {
        handleSubmit(0);
        //setSelectedEntry(null);
    }

    const handleUserChange = (e) => {
        setUAID(e.target.value);
    }
    const handlePracChange = (e) => {
        setPracID(e.target.value);
    }

    const noresult = entries.length===0 && searchdone===true;
    const userlist = matrix.users || [];
    const praclist = matrix.practices || [];
    return (
        <React.Fragment>
            <div><div className={styles.title}>Search CLOSED Tickets</div>
            <div className={styles.page}>
                <div className={styles.form} style={{marginTop:"40px"}}>
                    <label className={styles.formlabel}>
                        First Name:
                        <input className={styles.forminput} type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabel}>
                        Last Name:
                        <input className={styles.forminput} type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabel}>
                        DOB:
                        <input className={styles.forminput} type="date" value={birthday} onChange={e => setBirthday(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabel}>
                        Phone Number:
                        <input className={styles.forminput} type="tel" placeholder="518 555-1212" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabel}>
                        Ticket Number:
                        <input className={styles.forminput} type="text" placeholder="123456" value={ticketNumber} onChange={e => setTicketNumber(e.target.value)} />
                    </label>
                    <br />
                    <div style={{marginLeft:"200px",display:"flex"}}>
                        <GenButton buttontext="Submit" width="80" onclick={handleSubmit} fontsize="18px" />
                        {ischild && <div style={{marginLeft:"16px"}}><GenButton buttontext="Cancel Call" width="110" onclick={onCancel} fontsize="16px" /></div>}
                    </div>
                    
                </div>
                <div className={styles.formright}>
                    <label className={styles.formlabelright}>
                        Users:
                        <select name="users" onChange={handleUserChange} value={uaid} style={{height:"24px",minWidth:"350px",marginLeft:"5px",zIndex:"1"}}>
                            <option value="0"></option>
                            {userlist.map((u,i)=> {
                                return <option key={"u_"+i} value={u.uaid}>{u.name}</option>
                            })}
                        </select>
                    </label>
                    <br />
                    <label className={styles.formlabelright}>
                        Practices:
                        <select name="practices" onChange={handlePracChange} value={pracid} style={{height:"24px",minWidth:"350px",marginLeft:"5px",zIndex:"1"}}>
                            <option value="0"></option>
                            {praclist.map((p,i)=> {
                                return <option key={"p_"+i} value={p.pracid}>{p.name}</option>
                            })}
                        </select>
                    </label>
                    <br />
                    <label className={styles.formlabelright}>
                        From Date:
                        <input name="fromdt" type="date" style={{height:"16px",minWidth:"220px",marginLeft:"5px",zIndex:"1"}} value={fromdt} onChange={(e)=>setFromDt(e.target.value)} />
                    </label>
                    <br />
                    <label className={styles.formlabelright}>
                        To Date:
                        <input name="todt" type="date" style={{height:"16px",minWidth:"220px",marginLeft:"5px",zIndex:"1"}} value={todt} onChange={(e)=>setToDt(e.target.value)} />
                    </label>
                </div>
            </div>
            <div>
                {entries.length > 0 && <ShowResults />}
                {noresult && <div style={{marginTop:"20px",marginLeft:"20px",fontSize:"18px"}}>No results found.</div>}
            </div>
            {/*<ShowButtons />*/}
            {selectedEntry && <TicketDetail 
                                globals={props.globals} 
                                methods={props.methods} 
                                allowpriorityedit={false} 
                                fromsearch={true} 
                                ticket={selectedEntry} 
                                doClose={ticketOnClose} 
                                closeticket={SetCloseOK} 
                                />}
            {shownote && <AddNote globals={props.globals} methods={props.methods} showreason={false} onSave={saveNote} onCancel={cancelNote} />}
            {showhold && <GenConfirm 
                globals={props.globals} 
                methods={props.methods}
                width="400"
                text="Place the selected ticket on Hold?"
                onCancel = {SetHoldCancel}
                onConfirm = {SetHoldOK}
                cancelText = "No"
                confirmText = "Yes" />}
            {showclose && <GenConfirm 
                globals={props.globals} 
                methods={props.methods}
                width="400"
                text="Close the selected ticket?"
                onCancel = {SetCloseCancel}
                onConfirm = {SetCloseOK}
                cancelText = "No"
                confirmText = "Yes" />}
        </div>

        </React.Fragment>

    )
}

export default ClosedTickets;